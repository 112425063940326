<!--快检异常处理表单-->
<template>
    <div class="exceptionHandlingForm" v-loading="loading">
        <div class="fomeStyle_dy baseInfo">
            <table>
                <tbody>
                    <tr>
                        <th>异常处理编号</th>
                        <td>{{ qualityUnusual.no }}</td>
                        <th>供应单编号</th>
                        <td>
                            <el-input
                                placeholder="选择供应单"
                                v-model="qualityUnusual.task_number"
                                readonly
                                @focus="showTaskPop"
                                :disabled="IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>供应商</th>
                        <td>{{ qualityUnusual.supplier_name }}</td>
                        <th>运输单位</th>
                        <td>{{ qualityUnusual.transport_name }}</td>
                    </tr>
                    <tr>
                        <th>卸料场站</th>
                        <td>{{ qualityUnusual.station_name }}</td>
                        <th>运输时间</th>
                        <td>
                            <el-date-picker
                                v-model="qualityUnusual.transport_time"
                                type="datetime"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                prefix-icon="a"
                                :disabled="IsRead"
                            >
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <th>原材料大类</th>
                        <td>{{ qualityUnusual.type_name }}</td>
                        <th>原材料子类</th>
                        <td>{{ qualityUnusual.child_type_name }}</td>
                    </tr>
                    <tr>
                        <th>货源地</th>
                        <td>{{ qualityUnusual.source_name }}</td>
                        <th>原材料规格</th>
                        <td>{{ qualityUnusual.spec_name }}</td>
                    </tr>
                    <tr>
                        <th>总车次</th>
                        <td>{{ totalCars }}</td>
                        <th>总重量</th>
                        <td>{{ totalWeight }}</td>
                    </tr>
                    <tr>
                        <th>检测结果</th>
                        <td>{{ qualityUnusual.results }}</td>
                        <th>更改检测结果</th>
                        <td>{{ qualityUnusual.change_result||'退货' }}</td>
                    </tr>
                    <tr>
                        <th>经办时间</th>
                        <td>{{ qualityUnusual.created }}</td>
                        <th>经办人</th>
                        <td>{{ qualityUnusual.creator_name }}</td>
                    </tr>
                    <tr>
                        <th>
                            <div class="cell-big">
                                异常处理说明
                            </div>
                        </th>
                        <td colspan="3">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入异常处理说明"
                                v-model="qualityUnusual.remarks"
                                :readonly="IsRead"
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-wrapper table-bg">
            <div class="choose-btn" @click="showBalancePop">
                选择
            </div>
            <el-table
                :data="balanceList"
                style="width: 100%"
                class="table-list"
                height="2.4rem"
                max-height="4rem"
            >
                <el-table-column
                    prop="balance_number"
                    label="过磅单列表"
                    class-name="merge-cell"
                >
                    <el-table-column
                        prop="balance_number"
                        label="过磅单号"
                        width="220"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="plate_number"
                        label="车牌号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="tare_time"
                        label="进场时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="delivery_time"
                        label="出场时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="net_weight"
                        label="净重"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="result"
                        label="检测结果"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="instock_state"
                        label="入库状态"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.instock_state?' 已入库':'未入库' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="instock_time"
                        label="入库时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="stock_no"
                        label="料仓号"
                    >
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import utils from '@/utils/util';
export default {
    name: 'exception-handling-form',
    props: {
        vueFormParams: Object,
    },
    data() {
        return {
            loading: false,
            business_instance_id: '',
            // 快检异常处理信息
            qualityUnusual: {
                id: '',
                no: '',
                business_instance_id: '',
                task_number: '',
                supplier_name: '',
                supplier_id: '',
                transport_id: '',
                transport_name: '',
                station_id: '',
                station_name: '',
                type_code: '',
                type_dic_code: '',
                type_name: '',
                child_type_code: '',
                child_type_dic_code: '',
                child_type_name: '',
                spec_code: '',
                spec_dic_code: '',
                spec_name: '',
                source_id: '',
                source_name: '',
                transport_time: '',
                results: '',
                change_result: '',
                remarks: '',
                opinion: '',
                approval_status: 0,
                creator_name: '',
                created: '',
            },
            // 过磅单列表
            balanceList: [],

            IsRead: false,
        };
    },
    computed: {
        // 总重量
        totalWeight() {
            let num = 0;
            this.balanceList.forEach(item => {
                num += item.net_weight;
            });
            return num;
        },
        // 总车次
        totalCars() {
            return this.balanceList.length;
        },
    },
    created() {
        this.business_instance_id = this.vueFormParams.businessInstanceId;
        this.IsRead = this.vueFormParams.IsRead;
        this.initPage();
    },
    methods: {
        // 初始化页面
        initPage() {
            this.getData();
        },
        // 获取数据
        getData() {
            this.loading = true;
            const url = '/interfaceApi/procurement/balance/quality/unusual/handle/' + this.business_instance_id;
            this.$axios.get(url).then(res => {
                if (res.balances) {
                    this.qualityUnusual = res.qualityUnusual;
                    res.balances.forEach(item => {
                        if (item.result === 1) {
                            item.result = '合格';
                        } else if (item.result === 2) {
                            item.result = '让步';
                        } else if (item.result === 3) {
                            item.result = '退货';
                        } else if (item.result === 4) {
                            item.result = '不合格';
                        }

                    });
                    this.balanceList = res.balances;
                } else {
                    this.qualityUnusual.creator_name = this.$takeTokenParameters('Name');
                    this.qualityUnusual.business_instance_id = this.business_instance_id;
                    this.qualityUnusual.created = utils.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
                }
                this.loading = false;
            });
        },
        // 选择任务编号
        showTaskPop() {
            this.$toast({
                title: true,
                text: '选择供应单',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'kjyccl_xzgyd',
                    },
                    selectTypeCallback: this.confirmChooseTask,
                },
            });
        },
        // 确认选择任务单
        confirmChooseTask(data) {
            this.qualityUnusual.child_type_code = data.child_type_code;
            this.qualityUnusual.child_type_dic_code = data.child_type_dic_code;
            this.qualityUnusual.child_type_name = data.child_type_name;
            this.qualityUnusual.task_number = data.no;
            this.qualityUnusual.source_id = data.source_id;
            this.qualityUnusual.source_name = data.source_name;
            this.qualityUnusual.spec_code = data.spec_code;
            this.qualityUnusual.spec_dic_code = data.spec_dic_code;
            this.qualityUnusual.spec_name = data.spec_name;
            this.qualityUnusual.station_id = data.station_id;
            this.qualityUnusual.station_name = data.station_name;
            this.qualityUnusual.supplier_id = data.supplier_id;
            this.qualityUnusual.supplier_name = data.supplier_name;
            this.qualityUnusual.transport_id = data.transport_id;
            this.qualityUnusual.transport_name = data.transport_name;
            this.qualityUnusual.type_code = data.type_code;
            this.qualityUnusual.type_dic_code = data.type_dic_code;
            this.qualityUnusual.type_name = data.type_name;
        },
        // 显示选择过磅单弹框
        showBalancePop() {
            if (this.IsRead) {
                return;
            }
            if (!this.qualityUnusual.task_number) {
                this.$message.warning('请先选择供应单');
                return;
            }
            this.$toast({
                title: true,
                text: '选择过磅单',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'kjyccl_xzgbd',
                        QueryString: 'task_number=' + this.qualityUnusual.task_number,
                    },
                    selectTypeCallback: this.confirmChooseBalance,
                },
            });
        },
        confirmChooseBalance(list) {
            if (!this.qualityUnusual.task_number) {
                this.$message.warning('请先选择供应单');
                return;
            }
            // 去重
            const result = [];
            list.forEach(item => {
                const blanceData = this.balanceList.find(balance => item.balance_number === balance.balance_number);
                if (!blanceData) {
                    result.push(item);
                }
            });
            // qualityUnusual.results
            this.balanceList.push(...result);
            const arr = [];
            this.balanceList.forEach(item => {
                if (item.result.indexOf('合格') !== -1 && !arr.includes('合格')) {
                    arr.unshift('合格');
                } else if (item.result.indexOf('让步') !== -1 && !arr.includes('让步')) {
                    arr.push('让步');
                }
            });
            this.qualityUnusual.results = arr.join();
        },
        // 保存
        save(successCallback, errorCallback) {
            if (!this.qualityUnusual.task_number) {
                this.$message.warning('请先选择供应单');
                errorCallback();
                return;
            }
            const url = '/interfaceApi/procurement/balance/quality/unusual/handle/save';
            const balanceNumbers = this.balanceList.map(item => {
                return item.balance_number;
            });
            const params = {
                qualityUnusual: this.qualityUnusual,
                balanceNumbers: balanceNumbers,
            };
            this.$axios.post(url, params).then(res => {
                if (res) {
                    this.qualityUnusual = res;
                    window.formEditingStatus = false;
                    successCallback(res.no);
                }
            }).catch(error => {
                errorCallback();
                this.$message.error(error.ErrorCode.Message);
            });
        },

    },
};
</script>

<style lang="stylus" scoped>
.exceptionHandlingForm
    width 100%;
    height 100%;
    padding 0.15rem;
    .baseInfo
        height auto
        padding 0
    .table-wrapper
        position relative
        margin-top 0.1rem
        .choose-btn
            position absolute
            top 0.08rem
            right 20px
            display flex
            padding 0.02rem 0.08rem
            background #022782
            font-size 0.14rem
            color #fff
            border-radius 0.05rem
            cursor pointer
            z-index 9
        >>> .merge-cell
            border-bottom 1px solid #ebeef5!important
            .cell
                padding-left 0.26rem
                text-align left
        >>> .cell
            padding 3px 0
            font-size 0.16rem
            font-weight normal
</style>
